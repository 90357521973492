export interface ResourceContractor {
  id?: number;
  displayed?: boolean;
  contractorActivityAmount?: number;
  contractorActivityOrder?: string;
  contractorActivityServiceOrder?: boolean;
  contractorActivityVendorJustification?: string;
  contractorActivityName?: string;
  contractorActivityNumber?: number;
  readiness: string | undefined;
}

export interface ResourceContractorInput {
  id?: number ;
  displayed?: boolean;
  contractorActivityAmount?: number;
  contractorActivityOrder?: string;
  contractorActivityServiceOrder?: boolean;
  contractorActivityVendorJustification?: string;
  contractorActivityName?: string;
  contractorActivityNumber?: number;
}
