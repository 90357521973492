export const environment = {
  production: true,
  apiBaseUrl: "https://aggregator-qa.jbid.iasp.tgscloud.net/aggregator",
  quantumBaseUrl: "https://navigator.phusionim.com/",
  instrumentationKey: "d543a3c6-9259-4dbb-909e-2243b3305a40",
  clientId: "4a0eb17b-54b3-42e6-8e77-431054288012",
  tenantId: "329e91b0-e21f-48fb-a071-456717ecc28e",
  aadBackendScope: "api://17d85a1f-80ee-46d0-9f28-eaa502d39367/aggregator_scope",
  redirectUrl: "/jobid-card/list",
  angolaUsers: "Tepa-Users",
  denmarkUsers: "Tepdk-Users",
  nigeriaDWUsers: "Tepng-dw-Users",
  nigeriaJVUsers: "Tepng-jv-Users",
  hotjarEnvironmentId: "3320166",
  linkJobcardToJcmActive: true,
};
